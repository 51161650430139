import { Route, Routes } from "react-router-dom";

import Homepage from "@/components/homepage";
import Menu from "@/components/menu";

function RoutesManager() {
  return (
    <Routes>
      <Route path="/" element={ <Homepage /> } />
      <Route path="/menu" element={ <Menu /> } />
    </Routes>
  );
}

export default RoutesManager;
