import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Section from "@/components/section";


function Menu() {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [errorOnMenu, setErrorOnMenu] = useState(false);
  let history = useNavigate();
  
  useEffect(() => {
    getMenu();
  }, []);

  async function getMenu() {
    setLoading(true);
    let apiData;
    try {
      apiData = await fetch('https://namasce.com/test/api/sections?populate[items][fields][0]=*&populate[subsections][populate][items][fields][0]=*', {
        headers: {
          "Authorization": `Bearer ${apiToken}`
        }
      });
    } catch (e) {
      setErrorOnMenu(true);
      setLoading(false);

      return;
    }

    if (!apiData) {
      setErrorOnMenu(true);
      setLoading(false);

      return;
    }
    
    let awaitedData = await apiData.json();

    setData(awaitedData);
    setLoading(false);
  }

  function navigateToHomepage(): void {
    history('/');
  }

  function buildSections(sections: any) {
    let sectionsList = [];

    for (const section of sections) {
      sectionsList.push(
        <Section section={section} key={`section-${sectionsList.length}`}/>
      );
    }

    return sectionsList;
  }

  if (loading || ( !data && !errorOnMenu )) {
    return (
      <div className="menu-page center-elements">
        <div className="loader" />
      </div>
    );
  }

  if (errorOnMenu) {
    return (
      <div className="menu-page center-elements">
        <div className="error-on-menu">
          Si è verificato un errore durante il caricamento del menu.
        </div>
      </div>
    );
  }

  return (
    <div className="menu-page center-elements-horizontally">
      <div className="sections-list">{ buildSections(data.data) }</div>
      <button className="close-btn" onClick={() => navigateToHomepage()}>CLOSE</button>
    </div>
  );
}

export default Menu;

const apiToken = 'b6c274fbd823e3e8f9b13d4f327938db09dbee16fbcd75c27ebcdec55ac32599b362482a8475b762af4934eab9f500fdcb22a094e92aec4940f7003d9de768c37cf32bbb058c621a0c9fadb595f195e0bd9fcfcc376c1a05d3049cc87201835aa79248e93623b7d958b67d3156a8088750415db59f757677bd248a3d811be1a8';
