import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InstagramIcon from './instagram-icon';
import Images from "@/components/homepage/image.json";

function Homepage() {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [errorOnHomepage, setErrorOnHomepage] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    getInfo();
  }, []);

  async function getInfo() {
    setLoading(true);
    let apiData;
    try {
      apiData = await fetch('https://namasce.com/test/api/homepage-info', {
        headers: {
          "Authorization": `Bearer ${apiToken}`
        }
      });
    } catch (e) {
      setErrorOnHomepage(true);
      setLoading(false);

      return;
    }

    if (!apiData) {
      setErrorOnHomepage(true);
      setLoading(false);

      return;
    }
    
    let awaitedData = await apiData.json();

    setData(awaitedData);
    setLoading(false);
  }

  function navigateToMenu(): void {
    history('/menu');
  }

  if (loading || ( !data && !errorOnHomepage )) {
    return (
      <div className="homepage center-elements">
        <div className="loader" />
      </div>
    );
  }

  if (errorOnHomepage) {
    return (
      <div className="homepage center-elements">
        <div className="error-on-homepage">
          Si è verificato un errore durante il caricamento dell'homepage.
        </div>
      </div>
    );
  }

  return (
    <div className = 'homepage container'>
    <img className = 'logo order-2' src = {Images.logo.path}/>
    <h2 className = 'time order-2'> {data.data.attributes.open_days} {data.data.attributes.open_hours}</h2>
    <a className = 'address order-2' href={data.data.attributes.address_link}>
      <h3 className = 'address-text order-2'> {data.data.attributes.address} </h3>
    </a>
    <button className = 'button order-2' onClick={ () => navigateToMenu() }>MENU</button>
    <InstagramIcon className='icon order-1' link={data.data.attributes.instagram_link}/>
  </div>
  )
}

export default Homepage;

const apiToken = 'b6c274fbd823e3e8f9b13d4f327938db09dbee16fbcd75c27ebcdec55ac32599b362482a8475b762af4934eab9f500fdcb22a094e92aec4940f7003d9de768c37cf32bbb058c621a0c9fadb595f195e0bd9fcfcc376c1a05d3049cc87201835aa79248e93623b7d958b67d3156a8088750415db59f757677bd248a3d811be1a8';
