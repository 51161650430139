
interface Props {
  section: any
}

function Section(props: Props) {

  function buildItems(items: any, subsections: any[]) {
    let itemsList = [];

    for (const item of items) {
      itemsList.push(
        <div className="item-container" key={`item-${props.section.attributes.name}-${itemsList.length}`}>
          <div className="item-title-row">
            <div className="item-name">
              {item.attributes.name}
            </div>
            <div className="item-separator" />
            <div className="item-price">
              {item.attributes.price}
            </div>
          </div>
          <div className="item-ingredients">
            {item.attributes.ingredients}
          </div>
        </div>
      );
    }

    for (const subsection of subsections) {
    let subsectionItems = [];

      for (const item of subsection.attributes.items.data) {
        subsectionItems.push(
          <div className="item-container" key={`subsection-item-${props.section.attributes.name}-${subsection.attributes.name}-${subsectionItems.length}`}>
            <div className="item-title-row">
              <div className="item-name">
                {item.attributes.name}
              </div>
              <div className="item-separator" />
                <div className="item-price">
                  {item.attributes.price}
                </div>
              </div>
              <div className="item-ingredients">
                {item.attributes.ingredients}
              </div>
            </div>
          );
        }

        itemsList.push(
          <div className="subsection-container" key={`subsection-${subsection.attributes.name}`}>
            <div className="subsection-title">
              {subsection.attributes.name}:
            </div>
            <div>
              {subsectionItems}
            </div>
          </div>
        );
    }

    return itemsList;
  }

  return <div className="section-container">
    <div className="section-title">
      {props.section.attributes.name}
    </div>

    <div className="section-description">
      {props.section.attributes.description}
    </div>

    <div className="section-items-container">
      {buildItems(props.section.attributes.items.data, props.section.attributes.subsections.data)}
    </div>
  </div>;
}

export default Section;
