import './App.css';

import Routes from '@/components/route/RoutesManager';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>

        <div className="copyright">© {(new Date()).getFullYear()} - Namasce Milano</div>
    </div>
  );
}

export default App;
